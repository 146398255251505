import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getXToken = () => {
    const xToken = ""; // Get x-Token from .env
    return xToken;
};

const getUserToken = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("_auth"))
        ?.split("=")[1];
    return cookieValue ? decodeURIComponent(cookieValue) : null;
};

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, { endpoint }) => {
            if (endpoint === "addDoctorForm") {
                // Do not set Content-Type for multipart/form-data
            } else {
                headers.set("Content-Type", "application/json");
            }
            headers.set("x-token", getXToken());
            headers.set("Authorization", getUserToken());
            return headers;
        }
    }),
    reducerPath: "adminApi",
    tagTypes: ["User", "UserF", "Newsletter" , "doctorForm" , "contactForm","doctorsList"],
    endpoints: (build) => ({
        getUser: build.query({
            query: (id) => `user/s/${id}`,
            providesTags: ["User"],
        }),
        getLoggedInMembers: build.query({
            query: (id) => `aggr/logged`,
            providesTags: ["loggedInMembers"],
        }),
        addToNewsletter: build.mutation({
          query: ({ email }) => ({
              url: "user/newsletter",
              method: "POST",
              params: { email },
          }),
          providesTags: ["Newsletter"],
      }),
      sendMessage: build.query({
        query: (payload) => ({
            url: "forms/contact",
            method: "POST",
            body: payload, // Use body to send JSON payload
        }),
        transformResponse: (response) => response, // Optional: Customize response
        providesTags: ["contactForm"], // Provides tags for caching
        invalidatesTags: ["contactForm"], // Invalidates cache on success
    }),
    addDoctorForm: build.mutation({
        query: (formData) => ({
          url: "up/doctor",
          method: "POST",
          body: formData,
        }),
        transformResponse: (response) => response, // Optional: Customize response
        providesTags: ["doctorForm"], // Provides tags for caching
        invalidatesTags: ["doctorForm"], // Invalidates cache on success
    }),
    getDoctorForms: build.query({
        query: () => "up/doctor",
        transformResponse: (response) => response, // Optional: Customize response
        providesTags: ["doctorsList"], // Provides tags for caching
        invalidatesTags: ["doctorsList"], // Invalidates cache on success
    }),
    acceptDoctor: build.mutation({
        query: (id) => ({
            url: `doc/accept/${id}`,
            method: "POST",
        }),
        invalidatesTags: ["doctorsList"],
    }),
    denyDoctor: build.mutation({
        query: (id) => ({
            url: `doc/deny/${id}`,
            method: "POST",
        }),
        invalidatesTags: ["doctorsList"],
    }),
    }),
});

export const {
    useGetUserQuery,
    useGetLoggedInMembersQuery,
    useAddToNewsletterMutation,
    useLazySendMessageQuery,
    useAddDoctorFormMutation,
    useGetDoctorFormsQuery,
    useAcceptDoctorMutation,
    useDenyDoctorMutation

} = api;