import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/ArrowBack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../assets/logo.png";

const pages = [
  { name: "Accueil", path: "/" },
  { name: "A propos", path: "/about" },
  { name: "Contact", path: "/contact" },
];

const Nav = ({ color }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ background: "rgba(255,255,255,0.7)" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Left Section: Logo and Typography */}
          <Link to="/" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
            }}
          >
            <Box
              component="img"
              src={Logo}
              alt="Ferticonnect"
              sx={{ height: 60 }}
            />
            <Box sx={{
                display :'flex',
                flexDirection : "column",
                alignContent : "flex-end",
                justifyContent :"flex-end",
                alignItems :"flex-start"
            }} >
              <Typography
                variant="h3"
                color="black"
                fontFamily="Oswald"
                fontWeight="500"
                sx={{ mb: 0 , color: "#69A88D" }}
              >
                FertiConnect
              </Typography>
              <Typography
                variant="body2"
                color="black"
                fontWeight="normal"
                sx={{ fontSize: isMobile ? ".70em" :".9em" , color: "#69A88D" }}
              >
                Empowering your reproductive health and beyond
              </Typography>
            </Box>
          </Box>
          </Link>

          {/* Center Section: Pages */}
          {isMobile ? (
            <>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ ml: "auto" }} // Align the menu icon to the right
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                left: 0,
                top: 0,
                p: 0,
                m: 0,
              }}
              PaperProps={{
                sx: {
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                },
              }}
            >
              <IconButton
                onClick={handleCloseNavMenu}
                sx={{
                  position: "fixed",
                  top: '2rem',
                  right: '2rem',
                  margin: "0rem",
                  color: "white",
                  zIndex: 1300,
                }}
              >
                <CloseIcon />
              </IconButton>
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link to={page.path} style={{ textDecoration: 'none', color : isMobile ? "white" : color, fontSize: '1.5rem' }}>
                      {page.name}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evently",
                gap: 8,
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  component={Link}
                  to={page.path}
                  sx={{ color: color, textTransform: "none" , fontSize : "1.2rem" }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
          )}

          {/* Right Section: Connexion Button */}
          {!isMobile && (
            <Button
              variant="text"
              sx={{
                backgroundColor: 'transparent',
                fontWeight: "bold",
                fontSize :"1.2rem",
                borderRadius: "50px",
                textTransform: "none",
                color : "#69A88D" ,
              }}
              component={Link}
              to="https://www.ferticonnect.com/admin"
            >
              Connexion
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Nav;
