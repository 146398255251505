import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";

const ProductForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
    image: "" // Base64 Image
  });

  useEffect(() => {
    if (id) {
      axios.get(`/api/products/${id}`).then((response) => {
        setProduct(response.data);
      });
    }
  }, [id]);

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  // Handle Image Upload & Convert to Base64
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProduct({ ...product, image: reader.result });
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/api/products/${id}`, product);
      } else {
        await axios.post("/api/products", product);
      }
      navigate("/admin/products");
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4">{id ? "Modifier Produit" : "Ajouter Produit"}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nom du produit"
          name="name"
          value={product.name}
          onChange={handleChange}
          required
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          label="Prix"
          name="price"
          type="number"
          value={product.price}
          onChange={handleChange}
          required
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          label="Catégorie"
          name="category"
          value={product.category}
          onChange={handleChange}
          required
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          multiline
          rows={4}
          value={product.description}
          onChange={handleChange}
          required
          sx={{ mt: 2 }}
        />
        
        {/* Image Upload */}
        <Button
          variant="contained"
          component="label"
          sx={{ mt: 2 }}
        >
          Télécharger une image
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleImageChange}
          />
        </Button>

        {/* Preview Image */}
        {product.image && (
          <Box mt={2}>
            <Typography variant="subtitle1">Aperçu de l'image :</Typography>
            <img
              src={product.image}
              alt="Product"
              style={{ width: "200px", height: "150px", marginTop: "10px", borderRadius: "8px", objectFit: "cover" }}
            />
          </Box>
        )}

        <Button variant="contained" color="primary" type="submit" sx={{ mt: 3 }}>
          {id ? "Modifier" : "Ajouter"}
        </Button>
      </form>
    </Box>
  );
};

export default ProductForm;
