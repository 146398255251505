import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import Navbar from "../../components/navbar";
import HeroImg from "../../assets/hero_img.png";
import Logo from "../../assets/logo_white.png";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import calendatIco from "../../assets/calendar.png";
import coachIco from "../../assets/coach.png";
import fertiessentialsIco from "../../assets/fertiessentials.png";
import ntriIco from "../../assets/nutri.png";
import psychoIco from "../../assets/psycho.png";
import searchmedIco from "../../assets/searchmed.png";
import assistanceIaIco from "../../assets/assistanceIaIco.png";
import educIco from "../../assets/educIco.png";
import forumIco from "../../assets/forumImg.png";

import illustrationLandingImg from "../../assets/illustration_landing.png";

import mobilePreviewImg from "../../assets/mobilePreview.png";

import messengerIco from "../../assets/messengerImg.png";
import locationIco from "../../assets/locationImg.png";
import articlesIco from "../../assets/articlesImg.png";

import getItOnPlayStore from "../../assets/GooglePlayBtn.png";
import getItOnAppStore from "../../assets/AppStoreBtn.svg";

import arrowOneImg from "../../assets/arrowOne.png";
import arrowTwoImg from "../../assets/arrowTwo.png";

import celiaTestimonialsImg from "../../assets/celia.png";
import moahmmedTestimonialsImg from "../../assets/mohammed.png";
import sarahTestimonialsImg from "../../assets/sarah.png";
import Footer from "../../components/footer";

const Home = () => {
  const theme = useTheme(); // Access Material-UI theme
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect mobile screens

  const services = [
    {
      title: "Calendrier menstruel",
      icon: calendatIco,
      desc: "FertiConnect va bien au-delà d'un simple traceur de dates. C'est votre compagnon numérique qui décrypte les mystères de votre cycle menstruel avec une précision scientifique et une sensibilité unique.",
    },
    {
      title: "Suivi psychologique",
      icon: psychoIco,
      desc: "Que vous traversiez des moments de transition, de stress ou des défis émotionnels, notre approche holistique vous offre un soutien professionnel, confidentiel et totalement personnalisé.",
    },
    {
      title: "Suivi nutritionnel",
      icon: ntriIco,
      desc: "Optimisez votre santé spécifiquement conçue pour répondre aux besoins des Algériens et bénéficiez de consultations et programmes en ligne avec les meilleurs diététiciens.",
    },
    {
      title: "Forum Communautaire",
      icon: forumIco,
      desc: "Rejoignez notre forum communautaire pour échanger avec d'autres utilisateurs, partager vos expériences et poser vos questions librement sans révéler votre identité dans un environnement sécurisé.",
    },
    {
      title: "Fertiessentials",
      icon: fertiessentialsIco,
      desc: "Cette fonctionnalité est  le résultat d'une quête méticuleuse pour vous offrir les produits les plus adaptés à votre santé. Chaque référence est sélectionnée selon des critères stricts.",
    },
    {
      title: "Rechercher un médecin",
      icon: searchmedIco,
      desc: "Notre algorithme avancé prend en compte vos préférences, et vos critères médicaux pour vous proposer les meilleurs professionnels de santé. Trouvez votre médecin idéal n'a jamais été aussi simple.",
    },
    {
      title: "Coach sportif",
      icon: coachIco,
      desc: "Avec FertiConnect, transformez chaque séance d'entraînement en un moment de connexion avec vous-même, alliant performance physique et bien-être reproductif.",
    },
    {
      title: "Assistance AI",
      icon: assistanceIaIco,
      desc: "Conçue pour vous guider tout au long de votre parcours. Posez vos questions et obtenez des réponses instantanées sur divers sujets liés à la fertilité, et aux meilleures pratiques pour votre bien-être.",
    },
    {
      title: "Contenu Éducatif sur la Santé Reproductive",
      icon: educIco,
      desc: "Notre contenu éducatif varié est élaboré en collaboration avec des experts médicaux et des professionnels de la santé, garantissant ainsi que toutes les informations sont basées sur des preuves scientifiques.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, // Enable centered slides
    centerPadding: "0", // No extra padding around the center slide
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true, // Keep centering on medium screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true, // Center cards even on mobile
        },
      },
    ],
  };

  return (
    <Box>
      <section
        style={{
          backgroundImage: `url(${HeroImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <Navbar color="black" />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            justifyContent: isMobile ? "center" : "space-around",
            alignItems: "center",
            height: "80%", // Adjust to center vertically
            padding: isMobile ? "5em 2em 0em 2em" : "0 8em", // Adjust padding for mobile
            gap: "1em",
            textAlign: isMobile ? "center" : "left", // Center text for mobile
          }}
        >
          {/* Left Side Content */}
          <Box sx={{ maxWidth: isMobile ? "100%" : "45%" }}>
            <Typography
              variant="h3"
              fontWeight="bold"
              color="white"
              sx={{
                marginBottom: "1em",
                fontSize: isMobile ? "1.8rem" : "2.5rem",
              }}
            >
              FertiConnect <br />
              Votre source d'informations et de soins médicaux fiables
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#69A88D",
                color: "white",
                fontSize: isMobile ? "1.2rem" : "1.5rem",
                padding: isMobile ? ".6em 2em" : ".8em 2.5em",
                borderRadius: "25px",
                textTransform: "none",
                marginBottom: "1em",
              }}
              target="_blank"
              component={Link}
              to="https://ferticonnect.com/public/app/ferticonnect.apk"
            >
              Accéder à l’application
            </Button>
            <Typography
              variant="body2"
              fontWeight="normal"
              color="white"
              sx={{
                fontSize: isMobile ? "1rem" : "1.2rem",
                marginTop: "1em",
              }}
            >
              Si vous êtes médecin et vous souhaiter vous joindre à notre
              communauté,{" "}
              <Link
                to="/doctor-signup"
                style={{
                  color: "#69A88D",
                  textDecoration: "underline",
                  fontSize: isMobile ? "1rem" : "1.2em",
                }}
              >
                cliquez ici
              </Link>
            </Typography>
          </Box>

          {/* Right Side Logo */}
          {
            // Hide the logo on mobile screens
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={Logo}
                alt="FertiConnect Logo"
                sx={{
                  height: "auto",
                  width: isMobile ? "25em" : "30em",
                  userSelect: "none",
                }}
              />
            </Box>
          }
        </Box>
      </section>

      <section>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(138, 211, 222, 0.11)",
            padding: "7em 2em",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            fontWeight="bold"
            color="black"
            sx={{ marginBottom: "1em" }}
          >
            Les Services Que Nous Proposons
          </Typography>
          <Box
            sx={{
              width: "10%",
              height: "2px",
              background: "black",
              marginBottom: "2em",
            }}
          />
          <Typography
            variant="body1"
            color="black"
            sx={{
              maxWidth: "1200px",
              margin: "0 auto 2em",
              fontSize: "1.1rem",
              lineHeight: "1.8",
            }}
          >
            Nous vous offrons les meilleures options adaptées à vos besoins.
            Ajustez-les en fonction de votre santé et assurez-vous de bénéficier
            de soins avec nos médecins hautement qualifiés. N'hésitez pas à nous
            consulter pour déterminer le service le plus adapté à votre santé.
          </Typography>
          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
              padding: isMobile ? "0em" : "0em 10em",
            }}
          >
            <Slider {...settings}>
              {services.map((service, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: "0 1em", // Gap between cards
                  }}
                >
                  <Box
                    sx={{
                      width: "350px",
                      backgroundColor: "#fff",
                      borderRadius: "15px",
                      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                      padding: "1.5em",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "3em",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={service.icon}
                        alt={service.title}
                        sx={{
                          width: "60px",
                          height: "60px",
                          marginBottom: "1em",
                        }}
                      />
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color="black"
                        sx={{ marginBottom: "0.5em" }}
                      >
                        {service.title}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{
                        fontSize: "0.95rem",
                      }}
                    >
                      {service.desc}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "7em 2em",
            textAlign: isMobile ? "center" : "left",
            gap: "2em",
          }}
        >
          {/* Left Side: Image */}
          <Box
            component="img"
            src={illustrationLandingImg} // Replace with the actual path to the image
            alt="Partenaires FertiConnect"
            sx={{
              width: isMobile ? "100%" : "50%",
              maxWidth: "500px",
              height: "auto",
              marginBottom: isMobile ? "2em" : "0",
            }}
          />

          {/* Right Side: Text Content */}
          <Box sx={{ maxWidth: "600px" }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              color="black"
              sx={{ marginBottom: "1em" }}
            >
              Partenaires FertiConnect
            </Typography>
            <Box
              sx={{
                width: "10%",
                height: "2px",
                background: "black",
                marginBottom: "1em",
              }}
            />
            <Typography
              variant="body1"
              color="black"
              sx={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "2em",
              }}
            >
              On propose des soins de santé progressifs et abordables,
              accessibles sur mobile et en ligne pour tous. Pour nous, ce n’est
              pas seulement un travail – nous sommes fiers des solutions que
              nous offrons.
            </Typography>
            <Button
              component={Link}
              to="/about"
              variant="outlined"
              sx={{
                borderColor: "#69A88D",
                color: "#69A88D",
                textTransform: "none",
                fontSize: "1rem",
                padding: "0.5em 2em",
                borderRadius: "25px",
              }}
            >
              En savoir plus
            </Button>
          </Box>
        </Box>
      </section>

      {/* Download Application Section */}

      <section>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "stretch", // Ensure both sides have equal height
            justifyContent: "space-around",
            padding: isMobile ? "1em 2em" : "5em 5em", // Increased side padding for better spacing
            gap: "3em",
          }}
        >
          {/* Left Side: Text and Features */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Evenly space elements vertically
              maxWidth: isMobile ? "100%" : "50%",
              textAlign: isMobile ? "center" : "left",
              gap: "2em",
            }}
          >
            <Box>
              <Typography
                variant="h2"
                fontWeight="bold"
                color="black"
                sx={{ marginBottom: "1em" }}
              >
                Découvrez notre application mobile
              </Typography>
              <Typography
                variant="body1"
                color="black"
                sx={{ fontSize: "1rem", lineHeight: "1.8" }}
              >
                Our commitment to excellence and patient-centered care sets us
                apart as a leading healthcare provider.
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "2em" }}>
              {/* Features */}
              {[
                {
                  title: "Messagerie instantanée avec les médecins",
                  description:
                    "Our team of skilled and experienced medical professionals is dedicated to Lorem ipsum dolor sit amet.",
                  icon: messengerIco, // Replace with actual path
                },
                {
                  title: "Vous guide vers les cliniques à proximités",
                  description:
                    "Our team of skilled and experienced medical professionals is dedicated to Lorem ipsum dolor sit amet.",
                  icon: locationIco, // Replace with actual path
                },
                {
                  title: "Une multitude d'articles scientifiques",
                  description:
                    "Our team of skilled and experienced medical professionals is dedicated to Lorem ipsum dolor sit amet.",
                  icon: articlesIco, // Replace with actual path
                },
              ].map((feature, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2em",
                  }}
                >
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                    }}
                  >
                    <Box
                      component="img"
                      src={feature.icon}
                      alt={feature.title}
                      sx={{ width: "50px", height: "50px" }}
                    />
                    <Box>
                      <Typography variant="h6" fontWeight="bold" color="black">
                        {feature.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="black">
                    {feature.description}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "4em",
                justifyContent: isMobile ? "center" : "left",
              }}
            >
              <Box
                component="a"
                href="https://ferticonnect.com/public/app/ferticonnect.apk" // Replace with the actual Play Store link
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "inline-block", // Ensures proper focus and click handling
                }}
              >
                <Box
                  component="img"
                  src={getItOnPlayStore} // Replace with the actual path
                  alt="Google Play"
                  sx={{ width: "150px", cursor: "pointer" }} // Add cursor pointer for hover effect
                />
              </Box>
              <Box
              component="a"
              href="https://www.apple.com/app-store/"
              target="_blank"
              rel="noopener noreferrer"
              className="stop-hover"
              sx={{
                display: "inline-block",
              }}
            >
                <Box
                  component="img"
                  src={getItOnAppStore} // Replace with the actual path
                  alt="App Store"
                  sx={{ width: "150px", cursor: "pointer" }} // Add cursor pointer for hover effect
                />
              </Box>
            </Box>
          </Box>

          {/* Right Side: Mobile Image */}
          <Box
            component="img"
            src={mobilePreviewImg} // Replace with actual path
            alt="Mobile Preview"
            sx={{
              width: isMobile ? "100%" : "20%", // Maintain proper proportions
              maxWidth: "400px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Box>
      </section>

      <section>
        <Box
          sx={{
            backgroundColor: "rgba(138, 211, 222, 0.11)", // Background color with opacity
            padding: "5em 2em",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            color="black"
            sx={{ marginBottom: "2em" }}
          >
            Comment ça marche ?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2em",
              maxWidth: "100%",
              padding: "0 2em",
            }}
          >
            {/* Step 1 */}
            <Box sx={{ textAlign: "center", maxWidth: "300px" }}>
              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  backgroundColor: "#ECF1F6",
                  color: "#69A88D",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto 1em",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                }}
              >
                01
              </Box>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="black"
                sx={{ marginBottom: "0.5em" }}
              >
                Créer votre compte
              </Typography>
              <Typography
                variant="body2"
                color="black"
                sx={{ opacity: 0.8, lineHeight: "1.5" }}
              >
                Avant de prendre rendez-vous avec un médecin, vous devez vous
                inscrire sur le site.
              </Typography>
            </Box>

            <Box
              component="img"
              src={arrowTwoImg} // Replace with actual path
              alt="arrow"
              sx={{
                display: isMobile ? "none" : "block",
                width: isMobile ? "100%" : "20%", // Maintain proper proportions
                maxWidth: "400px",
                height: "auto",
                alignSelf: "center",
                transform: "translateY(-35px)",
              }}
            />

            {/* Step 2 */}
            <Box sx={{ textAlign: "center", maxWidth: "300px" }}>
              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  backgroundColor: "#ECF1F6",
                  color: "#69A88D",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto 1em",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                }}
              >
                02
              </Box>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="black"
                sx={{ marginBottom: "0.5em" }}
              >
                Trouver un médecin
              </Typography>
              <Typography
                variant="body2"
                color="black"
                sx={{ opacity: 0.8, lineHeight: "1.5" }}
              >
                Une fois inscrit, vous pouvez prendre rendez-vous avec le
                médecin via le formulaire en ligne.
              </Typography>
            </Box>

            <Box
              component="img"
              src={arrowOneImg} // Replace with actual path
              alt="arrow"
              sx={{
                display: isMobile ? "none" : "block",
                width: isMobile ? "100%" : "20%", // Maintain proper proportions
                maxWidth: "400px",
                height: "auto",
                alignSelf: "center",
                transform: "translateY(-35px)",
              }}
            />

            {/* Step 3 */}
            <Box sx={{ textAlign: "center", maxWidth: "300px" }}>
              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  backgroundColor: "#ECF1F6",
                  color: "#69A88D",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto 1em",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                }}
              >
                03
              </Box>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="black"
                sx={{ marginBottom: "0.5em" }}
              >
                Consultation
              </Typography>
              <Typography
                variant="body2"
                color="black"
                sx={{ opacity: 0.8, lineHeight: "1.5" }}
              >
                Après cela, vous pourrez rencontrer le médecin et lui parler de
                votre maladie.
              </Typography>
            </Box>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            padding: "5em 2em",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            color="black"
            sx={{ marginBottom: "1em" }}
          >
            Pourquoi choisir FertiConnect ?
          </Typography>
          <Typography
            variant="body1"
            color="black"
            sx={{ marginBottom: "3em", opacity: 0.8 }}
          >
            Ce que disent les utilisateurs de FertiConnect
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: isMobile ? "2em" : "5em",
              maxWidth: "1200px",
              margin: "0 auto",
            }}
          >
            {/* Testimonial 1 */}
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "300px",
                border: "1px solid #ECECEC",
                padding: "2em",
                borderRadius: "10px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evently",
                  alignItems: "center",
                  gap: "1em",
                }}
              >
                <Box
                  component="img"
                  src={sarahTestimonialsImg} // Replace with actual path
                  alt="Sara Brahimi"
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    marginBottom: "1em",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color="black">
                    Sara Brahimi
                  </Typography>
                  <Typography
                    variant="body2"
                    color="black"
                    sx={{ opacity: 0.8, marginBottom: "1em" }}
                  >
                    Patiente
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                color="black"
                sx={{ lineHeight: "1.5", textAlign: "left" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
              </Typography>
            </Box>

            {/* Testimonial 2 */}
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "300px",
                border: "1px solid #ECECEC",
                padding: "2em",
                borderRadius: "10px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evently",
                  alignItems: "center",
                  gap: "1em",
                }}
              >
                <Box
                  component="img"
                  src={moahmmedTestimonialsImg} // Replace with actual path
                  alt="Mohamed Riali "
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    marginBottom: "1em",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color="black">
                    Mohamed Riali
                  </Typography>
                  <Typography
                    variant="body2"
                    color="black"
                    sx={{ opacity: 0.8, marginBottom: "1em" }}
                  >
                    Patient
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                color="black"
                sx={{ lineHeight: "1.5", textAlign: "left" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
              </Typography>
            </Box>

            {/* Testimonial 3 */}
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "300px",
                border: "1px solid #ECECEC",
                padding: "2em",
                borderRadius: "10px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evently",
                  alignItems: "center",
                  gap: "1em",
                }}
              >
                <Box
                  component="img"
                  src={celiaTestimonialsImg} // Replace with actual path
                  alt="Célia Merdoug"
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    marginBottom: "1em",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color="black">
                    Célia Merdoug
                  </Typography>
                  <Typography
                    variant="body2"
                    color="black"
                    sx={{ opacity: 0.8, marginBottom: "1em" }}
                  >
                    Docteur
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                color="black"
                sx={{ lineHeight: "1.5", textAlign: "left" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
              </Typography>
            </Box>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            backgroundColor: "rgba(138, 211, 222, 0.11)", // Light blue background
            textAlign: "center",
            padding: "4em 2em",
            borderRadius: "25px",
            margin: isMobile ? "3em 2em" : "3em 6em",
          }}
        >
          <Typography
            variant="p"
            fontWeight="bold"
            color="black"
            sx={{ marginBottom: "0.5em", fontSize: isMobile ? "2em" : "4rem" }}
          >
            Débutez avec FertiConnect
          </Typography>
          <Typography
            variant="body2"
            color="black"
            sx={{
              opacity: 0.8,
              marginBottom: "2em",
              fontSize: "1.1rem",
            }}
          >
            Empowering your reproductive health and beyond
          </Typography>
          <Button
            component={Link}
            to="https://app.ferticonnect.com/"
            variant="contained"
            sx={{
              backgroundColor: "#69A88D", // Green color for the button
              color: "#FFFFFF",
              padding: "0.8em 2.5em",
              fontSize: "1rem",
              borderRadius: "25px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Commencer
          </Button>
        </Box>
      </section>

      <Footer />
    </Box>
  );
};

export default Home;
